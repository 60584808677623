<template>
  <el-dialog
    v-drag
    :title="`管理${title}`"
    :visible.sync="dialogVisible"
    width="520px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="comm-container">
      <div class="add">
        <p class="title">{{ `新增${title}` }}</p>
        <div class="form">
          <el-input v-model="dbValue" class="input" placeholder="请输入代码" />
          <el-input
            v-model="description"
            class="input"
            style="margin: 0; width: 160px"
            :placeholder="`请输入${title}`"
          />
          <div class="btn" @click="add">添加</div>
        </div>
      </div>
      <div class="table">
        <BaseTable :tableData="tableData" :height="240" :isPager="false">
          <template slot="opt" slot-scope="row">
            <i
              class="el-icon-delete"
              style="color: #2395f3; cursor: pointer"
              @click="deleteRow(row.scope)"
            />
          </template>
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('../table/BaseTable.vue')
import { addParams, deleteParams } from '@/api/system'
import { mapGetters } from 'vuex'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      //  1 = expressSupplier(快递服务商),
      //  2 = expressSupplierService(快递服务商服务类型),
      //  3 = weigtVolumeUnit(重量体积单位),
      //  4 = containerType(柜型),
      //  5 = portType(目的港),
      //  6 = airportType(目的机场),
      //  7 = currencyType(币制),
      //  8 = feeType(费用试算费用类型),
      //  9 = CompanyChangeRecord_Reason(修改客户账户原因),
      //  10 = UserSaleGroup(销售分组),
      //  11 = CompanyArea(客户所属区域)
      default: 1
    },
    dataColumn: {
      type: Array,
      default: function() {
        return []
      }
    },
    title: {
      type: String,
      default: '业务组别'
    }
  },
  data() {
    return {
      dbValue: '',
      description: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    tableData() {
      const arr = []
      this.dataColumn.map((item) => {
        arr.push({
          description: item.label,
          dbValue: item.value
        })
      })
      const obj = {
        fileds: [
          {
            filed: 'dbValue',
            label: '代码',
            width: '140px'
          },
          {
            filed: 'description',
            label: this.title,
            width: '140px'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '140px',
            isCustom: true
          }
        ],
        columns: arr,
        total: 0
      }
      return obj
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        this.dbValue = ''
        this.description = ''
      }
    }
  },
  methods: {
    add() {
      if (this.userInfo.type !== '管理员') {
        this.$message.error('没有权限，请联系管理员操作')
        return
      }
      if (!(this.dbValue && this.description)) {
        this.$message.error(`请完善编码或者${this.title}`)
        return
      }
      addParams({
        type: this.type,
        dbValue: this.dbValue,
        description: this.description
      }).then((res) => {
        if (res.success) {
          this.$message.success('添加成功')
          this.$emit('dataChange')
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    deleteRow(row) {
      if (this.userInfo.type !== '管理员') {
        this.$message.error('没有权限，请联系管理员操作')
        return
      }
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          deleteParams({
            ...row,
            type: this.type
          }).then((res) => {
            if (res.success) {
              this.$message.success('删除成功')
              this.$emit('dataChange')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok')
    }
  }
}
</script>

<style lang="less" scoped>
.comm-container {
  .table {
    width: 420px;
    height: auto;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-bottom: 0;
    margin: 0 auto;
  }
  .title {
    width: 420px;
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border: 1px solid #eeeeee;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 40px;
    text-align: center;
    margin: 0 auto;
  }
  .form {
    width: 420px;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
  }
  .btn {
    width: 79px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-right: 20px;
    cursor: pointer;
  }
  .input {
    margin-left: 20px;
    width: 120px;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.title {
  margin-top: 30px;
  margin-block: 16px;
}
</style>
