var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: `管理${_vm.title}`,
        visible: _vm.dialogVisible,
        width: "520px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "comm-container" }, [
        _c("div", { staticClass: "add" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(_vm._s(`新增${_vm.title}`)),
          ]),
          _c(
            "div",
            { staticClass: "form" },
            [
              _c("el-input", {
                staticClass: "input",
                attrs: { placeholder: "请输入代码" },
                model: {
                  value: _vm.dbValue,
                  callback: function ($$v) {
                    _vm.dbValue = $$v
                  },
                  expression: "dbValue",
                },
              }),
              _c("el-input", {
                staticClass: "input",
                staticStyle: { margin: "0", width: "160px" },
                attrs: { placeholder: `请输入${_vm.title}` },
                model: {
                  value: _vm.description,
                  callback: function ($$v) {
                    _vm.description = $$v
                  },
                  expression: "description",
                },
              }),
              _c("div", { staticClass: "btn", on: { click: _vm.add } }, [
                _vm._v("添加"),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c("BaseTable", {
              attrs: { tableData: _vm.tableData, height: 240, isPager: false },
              scopedSlots: _vm._u([
                {
                  key: "opt",
                  fn: function (row) {
                    return [
                      _c("i", {
                        staticClass: "el-icon-delete",
                        staticStyle: { color: "#2395f3", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteRow(row.scope)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }